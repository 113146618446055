import styled from 'styled-components'
import { Button, CheckBox, GridBox, LabelInput } from '..'
import { getTheme } from '@/styles/theme'
import { useSignup } from '@/stores/signup'
import { useState } from 'react'


const Container = styled.div`

`


export default function Step3 () {
  const { submit, signupData, handleChange, agreeServicePolicy } = useSignup();

  const [confirmPassword, setConfirmPassword] = useState('');

  function passwordConfirmation() {
    if(signupData.password === confirmPassword) submit();
    else return alert('비밀번호 확인 불일치')
  }

  return (
    <Container>
      <div className='black font-30 c-primary100'>회원가입</div>
      <div className='bold font-24 mt-4 mb-72'>오드너리가 처음이신가요?</div>

      <LabelInput value={signupData.password} onChange={e => handleChange('password', e.target.value)} label={"비밀번호"} type="password"/>
      <div className='mt-24'/>

      <LabelInput value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} label={"비밀번호 확인"} type="password"/>
      <div className='mt-24'/>
      



      <div className='flex align-center'>
        <CheckBox selected={signupData.agreedToServicePolicy.agreed} onClick={agreeServicePolicy}/>
        <div className='underline c-primary100'>서비스 이용약관</div>
        <div>에 동의합니다.</div>
      </div>

      <div className='mt-48'/>

      <Button background={getTheme('primary100')} color={'#fff'} fontSize={20} style={{width: '100%'}} onClick={passwordConfirmation}>완료</Button>

    </Container>
  )
}