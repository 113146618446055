import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { getTheme } from '@/styles/theme';

const NormalButton = styled.button`
  background: ${({ background }) => background ? background : getTheme('primary')};
  justify-content: center;
  align-items: center;

  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`}; 
   
  color: ${({ color }) => color};
  

  font-size: ${({fontSize}) => `${fontSize}px`};

  border: 0;
  border-radius: 6px;
  user-select: none;

  font-weight: 500;
  cursor: pointer;

  :hover {
    background-color: ${({hoverBackground}) => hoverBackground};
    color: ${({hoverColor}) => hoverColor};
  }

  transition: .3s;
`;

function Button({ children, background, to, color="#fff", onClick=null, width=200, fontSize=16, height=52, hoverBackground=getTheme('primary'), hoverColor=getTheme('white'), ...rest }) {
  const navigate = useNavigate();
  
  async function buttonClick () {
    if(to) {
      if(onClick) {
        await onClick()
        return navigate(to)
      }
      else return navigate(to)
    }
    else if(onClick) return onClick()
  }
  return ( 
    <>
      <NormalButton background={background} color={color} width={width} fontSize={fontSize} height={height} hoverBackground={hoverBackground} hoverColor={hoverColor} onClick={buttonClick} {...rest}>
        {children}
      </NormalButton>
    </>
  )
}

export default Button;
