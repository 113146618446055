import { formatDateYMD } from "@/utils/format";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  .c-878787 {
    color: #878787;
  }

  .white-box {
    width: 100%;
    height: 8px;
    background-color: #e7e6e7;
  }

  .c-929292 {
    color: #929292;
  }

  .episode-item {
    display: block;
    width: 100%; 
    border-bottom: 1px solid #e7e6e7;
    padding: 12px 20px;
  }
`

export function EpisodeList({ episodeList }) {
  const [sort_type, set_sort_type] = useState(true)

  return (
    <Container>
      <div className='flex-center px-20 w-full py-10'>
        <div className='flex-1 bold font-12'>총 {episodeList?.length}화 선공개</div>
        <div className='font-12 flex-center medium'>
          <div className={!sort_type ? 'c-primary' : 'c-878787'} onClick={() => set_sort_type(false)}>회차순</div>
          <div className='mx-2 c-878787 font-10'>|</div>
          <div className={sort_type ? 'c-primary' : 'c-878787'}  onClick={() => set_sort_type(true)}>등록순</div>
        </div>
      </div>

      <div className='white-box'/>

      {
        [...episodeList]?.sort((a, b) => {
          if(!sort_type) {
            return b?.episode_index - a?.episode_index;
          } else {
            return a?.episode_index - b?.episode_index;
          }
        })?.map((item, idx) => (
          <Link to={`/episode/${item.uuid}`} className='episode-item' key={`episode-${idx}`}>
            <div className='medium font-14 ellipsis-1 mb-2'>{item.title}</div>
            <div className='font-10 medium c-929292'>{formatDateYMD(item.updated_date)}</div>
          </Link>
        ))
      }
    </Container>
  )
}