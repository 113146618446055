import { Link, NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { ic_header_menu, ic_odi, ic_search, main_logo, sample_profile } from '@/assets'
import { GridBox } from '@/components'
import { useState } from 'react'
import { getTheme } from '@/styles/theme'
import { useUser } from '@/stores'
import { getUserData } from '@/utils'

const Container = styled.div`
  width: 100%;
  height: 52px;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 21;

  .innerHeader {
    width: 100%;
    background-color: #fff;
    padding: 0 16px;
    height: 100%;
  }

  
  .ic_search {
    width: 24px;
    height: 24px;
  }

  .active {
    color: #5c1b9c;
    font-weight: 900;
  }

  .grey_line {
    background-color: #fff;
  }



  
  
  .header_menu {
    width: 80vw;
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh);
    overflow: scroll;
    z-index: 23;
    transition: .3s;
    background-color: #fff;
    
    .sub_menu {
      display: block;
    }
    
    .active {
      color: ${getTheme('primary')}
    }


    .logo_box {
      height: 80px;
    }

    .purple_line {
      width: 100%;
      height: 3px;
      background-color: ${getTheme('primary100')};
    }
  }

  .header_menu_close {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
  }


  .history_box {
    border-radius: 8px;
    border: 2px solid #e1e1e1;
  }

  .odi_semismall {
    width: 23px;
  }
  .odi_small {
    width: 18px;
  }

  .grey_bottom_2 {
    border-bottom: 2px solid #e1e1e1;
  }

  .grey_bottom_1 {
    border-bottom: 1px solid #e1e1e1;
  }

  .price_box {
    border: 2px solid #000;
    width: 76px;
    height: 32px;
    border-radius: 8px;
  }

  .price_box_small {
    border: 2px solid #000;
    width: 52px;
    height: 24px;
    border-radius: 8px;
  }

  .profile_image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .c-4f4f4f {
    color: #4f4f4f;
  } 


  .header_menu_btn {
    img {
      width: 24px;
    }
    height: 24px;
    width: 24px;
  }
`

const StyledLink = styled(NavLink)`
  color: #5b5b5b;
`



export default function Header () {
  // [TODO] 유저 데이터 가져오는 방식 -> Recoil State로 변경
  const [user, ] = useState(getUserData())

  const { userLogout } = useUser()
  
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <Container className='' id="top-header">
      <div className='innerHeader relative flex-center'>
        <Link to ='/'>
          <img style={{width: '100px'}} src={main_logo}/>
        </Link>

        <div className='flex-1'></div>
        
        {/* <img className='ic_search mx-16' src={ic_search} onClick={() => alert('준비중입니다.')}/> */}

        <div className='header_menu_btn pointer flex-center'  onClick={() => {
          if(!user) return window.location.href = '/login';
          else setIsOpen(true)
        }}>
          <img src={ic_header_menu}/>
        </div>


        {isOpen && <div className='header_menu_close' onClick={() => setIsOpen(false)}/>}
        <div className='header_menu px-20 py-16' style={{ 
          left: isOpen ? '20vw' : '100vw',
        }}>
          <div className='w-full logo_box flex-center'>
            <Link to ='/'>
              <img style={{width: '100px'}} src={main_logo}/>
            </Link>
          </div>

          <div className='purple_line mb-24'/>

          <div className='w-full mb-20 profile_box flex-center px-14'>
            <img src={sample_profile} className='profile_image mr-4'/>
            
            <div className='flex flex-1 justify-center flex-col'>
              <div className='font-14 bold mb-2'>{user?.name ?? '닉네임'}</div>
              <div className='font-10 c-4f4f4f' >{user?.email ?? 'test@test.com'}</div>
            </div>
            <Link to='/profile' className='font-12 semibold'>프로필 설정</Link>
          </div>

          <div className='w-full history_box'>
            <div className='flex-center px-10 py-10 grey_bottom_2'>
              <img className='odi_semismall' src={ic_odi}/>
              <div className='ml-2 medium font-12'>남은 오디</div>
              <div className='flex-1 ml-6 font-14 semibold'>172</div>
              <div className='price_box pointer font-12 flex-center semibold' onClick={() => navigate('/charge')}>충전하기</div>

            </div>
            <div className='flex-center pl-12 pr-10 py-10'>
              <img className='odi_small' src={ic_odi}/>
              <div className='flex-1 ml-6 font-12 medium'>오디 자동충전</div>
              <div className='price_box_small font-10 pointer flex-center semibold' onClick={() => navigate('/charge')}>신청하기</div>
            </div>
          </div>

          <div className='purple_line mt-24'/>

          <div className='pointer bold font-16' onClick={() => userLogout()}>로그아웃</div>
        </div>



      </div>

      <div className='w-full grey_line font-18'>
        <div className='w-full flex flex-center px-20' style={{background: '#fff'}}>
          <StyledLink to='/' className='w-full flex-center bold py-12 flex-1'>홈</StyledLink>
          <StyledLink to='/rank' className='w-full flex-center bold py-12 flex-2'>랭킹</StyledLink>
          <StyledLink to='/audio' className='w-full flex-center bold py-12 flex-2'>오디오북</StyledLink>
          <StyledLink to='/uportunity' className='w-full flex-center bold py-12 flex-2'>어포튜니티</StyledLink>
        </div>
      </div>
    </Container>
  )
}

