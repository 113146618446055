import { ic_navigate_back } from '@/assets';
import Step1 from '@/components/SignupStep/step1';
import Step2 from '@/components/SignupStep/step2';
import Step3 from '@/components/SignupStep/step3';
import { useHideLayout } from '@/hooks'
import { useSignup } from '@/stores/signup';
import { useState } from 'react';
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;

  .pop-header {
    padding: 0 24px;
    position: fixed;
    top: 0;
    left: 0;
    height: 60px;
    background-color: #fff;

    img {
      width: 24px;
    }
  }
`


export default function Page () {
  const { step, decrementStep } = useSignup();

  useHideLayout('header', true);
  


  function stepRouter(step) {
    switch (step) {
      default:
      case 1:
        return <Step1/>;
      case 2:
        return <Step2/>;
      case 3:
        return <Step3/>;
    }
  }



  return (
    <Container>
      <div className='w-full pop-header flex align-center'>
        <img className='pointer' src={ic_navigate_back} onClick={decrementStep}/>
      </div>

      <div className='px-32'>
        {stepRouter(step)}
      </div>
    </Container>
  )
}