import { atom, useRecoilState } from 'recoil';
import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { getUserData } from '@/utils'

import axios from '@/lib/axios';

const userState = atom({
  key: 'User',
  default: {},
});



export const useUser = () => {
  const [user, setUser] = useRecoilState(userState);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate()

  const userLogout = async() => {
    setUser({})
    localStorage.removeItem('user_data')
    localStorage.removeItem('admin_data')
    localStorage.removeItem('user_access_token')
    localStorage.removeItem('user_refresh_token')

    window.location.href = '/login'
  }

  const userLocalSignin = async ({ id, password }) => {
    try {
      const result = await axios.post(`/userauth/login`, {
        email: id, password
      })
      
      localStorage.setItem('user_data', JSON.stringify({ email: id }))
      localStorage.setItem('user_access_token', result.data.accessToken)
      localStorage.setItem('user_refresh_token', result.data.refreshToken)

      if(result.data.status === 404) return false;
      localStorage.setItem('user_data', true)
      
      const resultProfile = await axios.post('/userauth/profile', {}, {
        headers: { 'Authorization': `Bearer ${result.data.accessToken}` }
      })

      localStorage.setItem('user_data', JSON.stringify(resultProfile.data))

      return true;
    } catch {
      return false
    }
  }


  return {
    user,
    setUser,
    isLoading,
    userLogout,

    getUserData, // 유저 데이터 가져오가
    userLocalSignin, // 유저 로컬 로그인
  };
};
