import { ic_scroll_down_black, sample_audio } from '@/assets';
import EpisodeBody from '@/components/Episode/EpisodeBody';
import EpisodeHeader from '@/components/Episode/EpisodeHeader';
import { useHideLayout } from '@/hooks';
import { useEpisodeData } from '@/stores/episode';
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { AudioVisualizer } from 'react-audio-visualize';
import { useEffect, useRef, useState } from 'react';

const Container = styled.div`
  padding-top: 140px;

  .episode_book_cover {
    width: 32%;
    max-width: 200px;
    border-radius: 10px;
  }
`


export default function Page () {
  const { uid } = useParams()
  const { episodeData } = useEpisodeData(uid)

  console.log(episodeData)

  useHideLayout('header', true)

  return (
    <Container className='block-draggable'>
      <EpisodeHeader
        novel_uuid={episodeData?.novel_uuid}
        novel_title={episodeData?.novel?.title}
        episode_title={episodeData?.title}
      />

      <div className="w-full pt-32 pb-48 ">
        <div className='w-full flex-col flex-center'>
          <img className='episode_book_cover' src={episodeData?.novel?.book_cover}/>
          <Visualizer/>
        </div>

        <div className='text-center font-bold text-sm' style={{ width: '80%', margin: '40px auto' }}>{episodeData?.title}</div>

        {episodeData?.content && <EpisodeBody content={episodeData?.content}/>}
        
        {episodeData?.next_uid && 
          <div onClick={() => window.location.href = `/episode/${episodeData?.next_uid}`} className='floatElement flex flex-center w-full'>
            <img style={{ width: '14px '}} src={ic_scroll_down_black}/>
            <div className='ml-10 font-14 bold'>다음으로</div>
          </div>
        }
      </div>
    </Container>
  )
}


const Visualizer = () => {
  const [blob, setBlob] = useState(null);
  const visualizerRef = useRef(null)

  // set blob somewhere in code
  useEffect(() => {
    fetch(sample_audio)
      .then(response => response.blob())
      .then(audioBlob => {
        setBlob(audioBlob);
      });
  }, []);

  return (
    <div>
      {blob && (
        <AudioVisualizer
          ref={visualizerRef}
          blob={blob}
          width={500}
          height={75}
          barWidth={1}
          gap={0}
          barColor={'#f76565'}
        />
      )}
    </div>
  )
}