import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  width: 80px;

  .book_cover {
    width: 80px;
    height: 108px;

    border-radius: 12px;
  }
`


export default function NovelItemSmall ({ book_cover, title, hasAudioBook, uuid }) {
  const navigate = useNavigate();

  return (
    <Container className='pointer mr-16' onClick={() => navigate(`/novel/detail/${uuid}`)}>
      <div className='w-full'>
        <img src={book_cover} className='w-full book_cover'/>
      </div>

      <div className='font-12 mt-6 medium w-full ellipsis-1'>{title}</div>
      <div className='font-12 mt-4 exlight'>
        <span>웹소설</span>
        {hasAudioBook && <span>, 오디오북</span>}
      </div>
    </Container>
  )
}