import { ic_scroll_down_black } from '@/assets';
import EpisodeBody from '@/components/Episode/EpisodeBody';
import EpisodeHeader from '@/components/Episode/EpisodeHeader';
import { useHideLayout } from '@/hooks';
import { useEpisodeData } from '@/stores/episode';
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 140px;

  .episode_book_cover {
    width: 64%;
    max-width: 200px;
    border-radius: 10px;
  }
`


export default function Page () {
  const { uid } = useParams()
  const { episodeData } = useEpisodeData(uid)

  console.log(episodeData)

  useHideLayout('header', true)

  return (
    <Container className='block-draggable'>
      <EpisodeHeader
        novel_uuid={episodeData?.novel_uuid}
        novel_title={episodeData?.novel?.title}
        episode_title={episodeData?.title}
      />

      <div className="w-full pt-32 pb-48 ">
        <div className='w-full flex-center'>
          <img className='episode_book_cover' src={episodeData?.novel?.book_cover}/>
        </div>

        <div className='text-center bold font-16' style={{ width: '80%', margin: '40px auto' }}>{episodeData?.title}</div>

        {episodeData?.content && <EpisodeBody content={episodeData?.content}/>}
        
        {episodeData?.next_uid && 
          <div onClick={() => window.location.href = `/episode/${episodeData?.next_uid}`} className='floatElement flex flex-center w-full'>
            <img style={{ width: '14px '}} src={ic_scroll_down_black}/>
            <div className='ml-10 font-14 bold'>다음으로</div>
          </div>
        }
      </div>
    </Container>
  )
}