'use client'

import { useEffect, useState } from "react";
import EpisodeSectionItem from "./EpisodeSectionItem";

export default function EpisodeBody ({ content }) {
  // 구간 데이터 선언
  const [contentData, setContentData] = useState([]);

  // 페이지 진입 시 실행
  useEffect(() => {
    getContentData();
  }, [])

  // 엔터 2개를 기준으로 구간 나누기
  function getContentData() {
    if(!content) setContentData([]);
    else {
      let content_slice_data = content?.split('\n\n');
      setContentData(content_slice_data);
    }
  }

  return (
    <div className="px-20 w-full mb-92">
      {/* 구간 전체 출력 */}
      {
        contentData.map((item, idx) => (
          <EpisodeSectionItem
            item={item}
            isLastItem={idx === contentData.length - 1}
            key={`novel-index-${idx}`}  
          />
        ))
      }

    </div>
  )
}