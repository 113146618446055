import axios from "@/lib/axios";


// 장르에 맞는 소설 리스트 가져오기
export const fetchNovelGenreList = async (genre, page) => {
  const { data } = await axios.get(`/novel/nlist/${page}/${genre}`);
  return data;
};

// 소설 상세 데이터 가져오기
export const fetchNovelDetailData = async (uuid) => {
  try {
    const { data } = await axios.get(`/novel/view/${uuid}`);
    return data;
  } catch (e) {
    alert('에러')
    console.log(e)
  }
}

// 소설 회차 리스트 가져오기
export const fetchNovelEpisodeList = async (uuid) => {
  const { data } = await axios.get(`/episode/list/${uuid}`);
  return data;
}


export const fetchNovelEpisodeData = async (uuid) => {
  const { data } = await axios.get(`/episode/detail/${uuid}`);
  return data;
}

