import { sample_banner } from '@/assets';
import React, { useState } from 'react';
import styled from 'styled-components';

const OuterContainer = styled.div`
  overflow: hidden;
  padding: 0 10px;
  position: relative;
`;

const InnerContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  margin: 0 -10px; /* Negative margin to show next/prev items partially */
  
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
  }
`;

const CarouselItem = styled.div`
  flex: 0 0 auto;
  width: calc(100% - 20px);
  margin: 0 10px;
  scroll-snap-align: center;
  border-radius: 10px;
  overflow: hidden; /* Ensure border-radius affects the image */
  
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

export default function Banner() {
  const items = [1, 2, 3, 4, 5]; // Example items

  return (
    <OuterContainer>
      <InnerContainer>
        {items.map((item, index) => (
          <CarouselItem key={index}>
            <img src={sample_banner} alt={`Banner ${item}`} />
          </CarouselItem>
        ))}
      </InnerContainer>
    </OuterContainer>
  );
}
