import { GridBox } from '@/components';
import Banner from '@/components/Banner';
import GenreSelector from '@/components/GenreSelector'
import NovelItem from '@/components/NovelItem';
import NovelItemSmall from '@/components/NovelItemSmall';
import { useNovelList } from '@/stores/novelList';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding: 134px 0;


  .novel-slider {
    display: flex;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
`




export default function MainPage () {
  const { novelList, goToNextPage } = useNovelList();
  const [searchParams, setSearchParams] = useSearchParams();

  const genreParam = searchParams.get('genre');
  return (
    <Container>
      <GenreSelector/>

      <div className='mt-20'/>

      {genreParam === '추천' && 
        <>
          <Banner/>

          <div className='pl-16 font-20 black mt-40'>
            <span>최근 본 </span>
            <span className='c-primary'>작품</span>
          </div>

          <div className='w-full novel-slider pt-16 pl-16'>
            {novelList?.map((item, key) => 
              <NovelItemSmall {...item} key={key}/>
            )}
          </div>

          <div className='pl-16 font-20 black mt-40'>
            <span>당신만을 위한 </span>
            <span className='c-primary'>추천</span>

          </div>
        </>
      }

      {genreParam !== '추천' &&
        <div className='pl-16 font-20 black'>
          <span>{genreParam} 중 </span>
          <span className='c-primary'>실시간 랭킹</span>
        </div>
      }

    
      
      
      <div className='px-16 w-full pt-16'>
        <GridBox col={3} gap={20}>
          {novelList?.map((item, key) => 
            <NovelItem {...item} key={key}/>
          )}
        </GridBox>
      </div>

    </Container>
  )
}