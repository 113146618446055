import { getImageUri } from '@/utils';
import { useEffect, useState } from 'react';
import styled from 'styled-components'


const Container = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  z-index: 99999;

  background-color: rgba( 10, 10, 10, 0.70 );


  .innerPopup {
    width: ${({isMobile}) => isMobile ? '90vw' : '480px'};
    height: ${({isMobile}) => isMobile ? '112.5vw' : '600px'};

    .popupImage {
      width: 100%;
      max-height: 100%;
    }

  }
  .popupClose {
    width: ${({isMobile}) => isMobile ? '90vw' : '480px'};
    background-color: #fff;
    height: 24px;
  }
`


export default function Popup ({
  _id,
  image,
  link,
  isMobile=false
}) {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if(isOpen) document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [isOpen])

  const HAS_VISITED_BEFORE = localStorage.getItem(`hasVisitedBefore_${_id}`);

  useEffect(() => {
    const handleShowModal = () => {
      if (HAS_VISITED_BEFORE && HAS_VISITED_BEFORE > new Date()) {
        return;
      }

      if (!HAS_VISITED_BEFORE) {
        setIsOpen(true);
      }
    };

    window.setTimeout(handleShowModal, 1000);
  }, [HAS_VISITED_BEFORE]);


  

  function onClosePopup() {
    let expires = new Date();
    expires = expires.setHours(expires.getHours() + 24);
    localStorage.setItem(`hasVisitedBefore_${_id}`, expires);
    setIsOpen(false)
  }

  return (
    <>
      {
        isOpen && 
        <Container className='flex-col flex-center' onClick={onClosePopup} isMobile={isMobile}>
          <a href={link} target={'_blank'}>
            <div className='innerPopup flex-col flex-center'>
              <img className='popupImage' src={getImageUri(image, 'popup')}/>
            </div>
          </a>

          <div className='pointer popupClose w-full text-center' onClick={onClosePopup}>오늘 하루 열지 않음</div>
        </Container>
      }
    </>
    
    
  )
}