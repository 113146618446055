import axios from "@/lib/axios";

export const fetchSignupUser = async (signupData) => {
  try {
    let signup_data = {...signupData};
    
    // [TODO] Date Picker 삽입
    signup_data.birth = new Date();

    // [TODO] 아래 두 데이터가 들어가지 않는 오류 발생.
    // 데이터 형 변환
    signup_data.agreedToPrivacyPolicy = [signupData.agreedToPrivacyPolicy];
    signup_data.agreedToServicePolicy = [signupData.agreedToServicePolicy];
    
    await axios.post('/userauth/register', signup_data);
    alert('가입 성공');
    return window.location.href = '/login';

  } catch (e) {
    console.log(e);
    return alert('가입 실패');
  }
}