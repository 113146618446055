import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { fetchNovelGenreList } from '@/api/novel';

const novelListState = atom({
  key: 'NovelList',
  default: [],
});

export const pageState = atom({
  key: 'pageState',
  default: 1,
});

export const genreState = atom({
  key: 'genreState',
  default: '',
});

export const useNovelList = () => {
  const [novelList, setNovelList] = useRecoilState(novelListState);
  const [page, setPage] = useRecoilState(pageState);
  const [genre, setGenre] = useRecoilState(genreState);
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    const genreParam = searchParams.get('genre');
    const pageParam = searchParams.get('page');

    if (genreParam) {
      setGenre(genreParam);
    }
    if (pageParam) {
      setPage(Number(pageParam));
    }

    const getNovelGenreList = async (genre, page) => {
      setIsLoading(true);
      try {
        const genreList = await fetchNovelGenreList(genre, page);
        // setNovelList([...novelList, ...genreList.data]); // Pagination 적용 시, Genre, Page 변환에 따라 초기화 필요
        setNovelList(genreList.data);
      } catch (error) {
        console.error('Failed to fetch novel genre list:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (genreParam) {
      getNovelGenreList(genreParam, pageParam || 1);
    }
  }, [searchParams, setGenre, setPage, setNovelList]);



  const goToNextPage = () => {
    setPage((prevPage) => prevPage + 1);
    setSearchParams('page', page + 1)
  };


  return {
    novelList,
    page,
    genre,
    isLoading,
    goToNextPage,
  };
};
