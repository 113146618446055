import { ic_audio_on, ic_comment, ic_like, ic_notification, ic_odi, ic_share } from '@/assets';
import { GridBox } from '@/components';
import AD from '@/components/AD';
import { AccordionMenu } from '@/components/AccordionMenu';
import { EpisodeList } from '@/components/Episode/EpisodeList';
import GenreSelector from '@/components/GenreSelector';
import { useNovelData } from '@/stores/novelData';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 154px;

  .book_cover {
    border-radius: 10px;
    width: 100px;
    height: 150px;
  }

  .white-line {
    width: 100%;
    height: 1px;
    background-color: #e7e6e7;
  }

  .white-box {
    width: 100%;
    height: 8px;
    background-color: #e7e6e7;
  }

  .episode-button {
    margin-top: 12px;
    border: 1.5px solid #5e5f5e;
    height: 52px;
    border-radius: 4px;
  }
  
  .odi_medium {
    width: 30px;
  }
  .odi_small {
    width: 18px;
  }

  .c-5d5d5d {
    color: #5d5d5d;
  }

  .price_box {
    border: 2px solid #000;
    width: 108px;
    height: 40px;
    border-radius: 8px;
  }

  .price_box_small {
    border: 2px solid #000;
    width: 76px;
    height: 32px;
    border-radius: 8px;
  }

  .grey_bottom_1 {
    border-bottom: 1px solid #e1e1e1;
  }
`


export default function Page () {
  const { uid } = useParams()
  const { novelData, episodeList } = useNovelData(uid)

  const navigate = useNavigate();

  
  function openFirstEpisode() {
    if(episodeList.length > 0) {
      const zero_item = episodeList?.find((item) => item.episode_index === 0);
      if(zero_item) navigate(`/episode/${zero_item?.uuid}`);

      const first_item = episodeList?.find((item) => item.episode_index === 1);
      if(first_item) navigate(`/episode/${first_item?.uuid}`);
    }
  }

  return (
    <Container className='block-draggable'>
      <GenreSelector/>

      <div className='px-20 mb-20'>
        <div className='flex'>
          {/* Book Cover Image */}
          {novelData?.book_cover && <img className='book_cover' src={novelData?.book_cover} alt='표지가 없습니다.' />}

          <div className='ml-12'>
            <div className='flex-center'>
              <div className='font-16 bold mr-10'>{novelData?.title}</div>
              {/* <img style={{width: '20px'}} src={ic_audio_on} onClick={() => navigate(`/audio/detail/${uid}`)}/> */}
            </div>
            <div className='font-14 mt-8'>{novelData?.writer_list?.join(', ')}</div>

            {!novelData?.release_note && <div className='mb-44'/>}
            {novelData?.release_note && <div className='mt-14 mb-20 font-12 bold'>{novelData?.release_note}</div>}
            
            <div className='mt-18 font-14'>{novelData?.genre?.join(', ')}</div>
            <div className='mt-2 font-12'>#{novelData?.keywords?.join(', #')}</div>
          </div>
        </div>
      </div>

      <div className='white-line mb-14'/>
      <div className='px-20 mb-12'>
        <GridBox col={4}>
          <div className='flex-col flex-center' onClick={() => alert('준비중입니다!')}>
            <img className='pointer' style={{height: '18px'}} src={ic_like}/>
            <div className='font-12 mt-2'>{0}</div>
          </div>
          <div className='flex-col flex-center' onClick={() => alert('준비중입니다!')}>
            <img className='pointer' style={{height: '18px'}} src={ic_comment}/>
            <div className='font-12 mt-2'>{0}</div>
          </div>
          <div className='flex-col flex-center' onClick={() => alert('준비중입니다!')}>
            <img className='pointer' style={{height: '20px'}} src={ic_notification}/>
            <div className='font-12 mt-2'>알람 설정</div>
          </div>
          <div className='flex-col flex-center ' onClick={() => alert('준비중입니다!')}>
            <img className='pointer' style={{height: '18px'}} src={ic_share}/>
            <div className='font-12 mt-2'>공유</div>
          </div>
        </GridBox>
      </div>

      <div className='px-20 mb-12'>
        <div className='episode-button flex-center font-20 bold' onClick={openFirstEpisode}>미리 첫화 보기</div>
      </div>

      <div className='white-line'/>

      <AccordionMenu content={novelData?.summary}/>
      <div className='flex-center px-20 py-10'>
        <img src={ic_odi} className='odi_medium'/>
        <div className='semibold font-16'>소장, 대여</div>
        <div className='ml-8 medium font-14 c-5d5d5d'>오디 1~2개 · 매열무</div>
        <div className='flex-1'/>
      </div>

      <div className='white-box'/>
      
      <div className='flex-center px-20 py-10 grey_bottom_1'>
        <img src={ic_odi} className='odi_medium'/>
        <div className='semibold font-16 medium'>남은 오디</div>
        <div className='ml-8 font-20 semibold'>172</div>
        <div className='flex-1'/>
        <div className='price_box pointer flex-center semibold' onClick={() => navigate('/charge')}>충전하기</div>
      </div>

      <div className='flex-center pl-26 pr-20 py-10'>
        <img src={ic_odi} className='odi_small'/>
        <div className='ml-6 semibold font-16 medium'> 오디 자동충전</div>
        <div className='flex-1'/>
        <div className='price_box_small font-12 pointer flex-center semibold' onClick={() => navigate('/charge')}>신청하기</div>
      </div>

      <div className='white-box'/>

      <AD/>
      
      <div className='white-box'/>
      
      <EpisodeList episodeList={episodeList}/>
    </Container>
  )
}