import { ic_audio_on } from '@/assets';
import AD from '@/components/AD';
import { AccordionMenu } from '@/components/AccordionMenu';
import { EpisodeList } from '@/components/Episode/EpisodeList';
import { useNovelData } from '@/stores/novelData';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 140px;

  .book_cover {
    border-radius: 10px;
    width: 100px;
    height: 150px;
  }

  .white-line {
    width: 100%;
    height: 1px;
    background-color: #e7e6e7;
  }

  .white-box {
    width: 100%;
    height: 8px;
    background-color: #e7e6e7;
  }

  .episode-button {
    margin-top: 12px;
    border: 1.5px solid #5e5f5e;
    height: 52px;
    border-radius: 4px;
  }
`


export default function Page () {
  const { uid } = useParams()
  const { novelData, episodeList } = useNovelData(uid)

  const navigate = useNavigate();

  function openFirstEpisode() {
    if(episodeList.length > 0) {
      const zero_item = episodeList?.find((item) => item.episode_index === 0);
      if(zero_item) navigate(`/episode/audio/${zero_item?.uuid}`);

      const first_item = episodeList?.find((item) => item.episode_index === 1);
      if(first_item) navigate(`/episode/audio/${first_item?.uuid}`);
    }
  }

  return (
    <Container className='block-draggable'>
      <div className='px-20 mb-20'>
        <div className='flex'>
          {/* Book Cover Image */}
          {novelData?.book_cover && <img className='book_cover' src={novelData?.book_cover} alt='표지가 없습니다.' />}

          <div className='ml-12'>
            <div className='flex-center'>
              <div className='font-16 bold mr-10'>{novelData?.title}</div>

              <img style={{width: '20px'}} src={ic_audio_on} onClick={() => navigate(`/audio/detail/${uid}`)}/>
            </div>
            <div className='font-14 mt-8'>{novelData?.writer_list?.join(', ')}</div>

            {!novelData?.release_note && <div className='mb-44'/>}
            {novelData?.release_note && <div className='mt-14 mb-20 font-12 bold'>{novelData?.release_note}</div>}
            
            <div className='mt-18 font-14'>{novelData?.genre?.join(', ')}</div>
            <div className='mt-2 font-12'>#{novelData?.keywords?.join(', #')}</div>
          </div>
        </div>
      </div>

      <div className='white-line mb-14'/>
      
      <div className='px-20 mb-12'>
        <div className='episode-button flex-center font-20 bold' onClick={openFirstEpisode}>미리 첫화 듣기</div>
      </div>

      <div className='white-line'/>

      <AccordionMenu content={novelData?.summary}/>
      <AccordionMenu content={novelData?.writer_note}/>

      <div className='white-box'/>

      <AD/>
      
      <div className='white-box'/>
      
      <EpisodeList episodeList={episodeList}/>
    </Container>
  )
}