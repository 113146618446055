import styled from "styled-components"
// import { ic_upload_file } from "assets"
import { useEffect, useRef, useState } from "react";
import { getImageUri } from "@/utils";

const FileUploadContainer = styled.div`
  width: 100%;
  color: #1a1a1a;
  
  .fileUploadUI {
    background-color: #000;
    border-radius: 4px;
    width: 91px;
    height: 28px;
  }
  .fileUploadBox {  
    border-radius: 24px;
    background-color: #f8f8f8;
    width: 100%;
    height: calc(100% - 38px);
  }

  .preview {
    width: 150px;
    height: 150px;
  }

  .fileUploadBox * {pointer-events: none;}


  .IconCircle {
    width: 60px;
    height: 60px;
    background-color: #fff;
    border-radius: 50%;
  }

  .t {
    color: #727272;
  }
`

export const FileUpload = ({ idx, label, className='', text, preview, accept, guide, efile, setEFile, maxSize, maxSizeStr }) => {  

  const drop = useRef(null);

  useEffect(() => {
    if(efile === null) {
      setImgFile("")
      setFilename('')
    }
  }, [efile])

  const [dragging, setDragging] = useState(false);
  const [imgFile, setImgFile] = useState("");
  
  const [filename, setFilename] = useState('')

  const onUpload = (file) => { // 파일 업로드 시 처리
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImgFile(reader.result);
   	};

    let AcceptType = [...accept.split(',')]

    if(AcceptType.includes(file.type)) {
      if(file.size > maxSize) alert('최대 파일크기 제한을 초과하였습니다.')
      else {
        setFilename(file.name)
        setEFile(file)
      }
    } else alert('파일 형식이 올바르지 않습니다!')
    
  };
  
  return (
    <FileUploadContainer className={`flex-col` + className}>
      {(preview && imgFile === "") && <img className="preview mb-24" src={getImageUri(preview)}/>}
      {imgFile && accept !== 'video/mp4' && <img className="preview mb-24" src={imgFile}/>}
      {imgFile && accept === 'video/mp4' && <video className="preview mb-24" src={imgFile}/>}

      <label className="fileUploadUI flex-center pointer c-white font-14" htmlFor={`fileUpload${idx}`}>파일 업로드</label>

      <input
        id={`fileUpload${idx}`} 
        type="file" 
        accept={accept}
        style={{display: 'none'}}
        onChange={e => {
          onUpload(e.target.files[0])
        }}
      />
    </FileUploadContainer>
  )
}