import { atom, useRecoilState } from 'recoil';
import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { getUserData } from '@/utils'

import axios from '@/lib/axios';
import moment from 'moment';
import { fetchSignupUser } from '@/api/user';

const signupState = atom({
  key: 'Signup',
  default: {
    name: '', // 이름
    password: '', // 비밀번호
    email: '', // 이메일
    gender: 0, // 성별
    phone: '', // 전화번호
    birth: null, // 생년월일

    agreedToPrivacyPolicy: {
      agreed: false, // 동의 여부 - 약식 동의
      date: null, // 일자 - 
      agreed_date: '', // 일자 - string
      hasReadAndAgreedTerms: false, // 잘 읽고 동의한건지에 대한 여부
      hasReadAndAgreedTermsDate: null, // 잘 읽고 동의한건지에 대한 여부
  		version: '' // 약관
    },

    agreedToServicePolicy: {
      agreed: false, // 동의 여부 - 약식 동의
      date: null, // 일자 - 
      agreed_date: '', // 일자 - string
      hasReadAndAgreedTerms: false, // 잘 읽고 동의한건지에 대한 여부
      hasReadAndAgreedTermsDate: null, // 잘 읽고 동의한건지에 대한 여부
  		version: '' // 약관
    },
    
  },
});

const stepState = atom({
  key: 'Step',
  default: 1,
});


export const useSignup = () => {
  const [signupData, setSignupData] = useRecoilState(signupState);
  const [isLoading, setIsLoading] = useState(false);

  const [step, setStep] = useRecoilState(stepState)
  
  
  const navigate = useNavigate()


  // step을 1 증가시키는 함수
  const incrementStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  // step을 1 감소시키는 함수
  const decrementStep = () => {
    if(step - 1 > 0) setStep((prevStep) => prevStep - 1);
    else navigate('/login');
  };

  // 회원가입 전송
  const submit = async () => {
    await fetchSignupUser(signupData);
  }

  // 데이터 변경
  const handleChange = (key, value) => {
    setSignupData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  // 개인정보 약관 동의
  const agreePrivacyPolicy = () => {
		let nowDateD = moment().toDate();
		let nowDate = moment().format('YYYYMMDD-HH:mm:ss');

    if(!signupData.agreedToPrivacyPolicy.agreed) // 동의의 경우
      setSignupData((prevData) => ({
        ...prevData,
        agreedToPrivacyPolicy: {
          agreed: true, // 동의 여부 - 약식 동의
          date: nowDateD, // 일자 - 
          agreed_date: nowDate, // 일자 - string
          hasReadAndAgreedTerms: true, // 잘 읽고 동의한건지에 대한 여부
          hasReadAndAgreedTermsDate: nowDateD, // 잘 읽고 동의한건지에 대한 여부
          version: '1.0' // [TODO] 약관 불러오기
        },
      }));
    else // 동의 취소의 경우
      setSignupData((prevData) => ({
        ...prevData,
        agreedToPrivacyPolicy: {
          agreed: false, // 동의 여부 - 약식 동의
          date: null, // 일자 - 
          agreed_date: '', // 일자 - string
          hasReadAndAgreedTerms: false, // 잘 읽고 동의한건지에 대한 여부
          hasReadAndAgreedTermsDate: null, // 잘 읽고 동의한건지에 대한 여부
          version: '' // 약관
        },
      }));
  }

  
  // 서비스 이용약관 동의
  const agreeServicePolicy = () => {
		let nowDateD = moment().toDate();
		let nowDate = moment().format('YYYYMMDD-HH:mm:ss');

    if(!signupData.agreedToServicePolicy.agreed) // 동의의 경우
      setSignupData((prevData) => ({
        ...prevData,
        agreedToServicePolicy: {
          agreed: true, // 동의 여부 - 약식 동의
          date: nowDateD, // 일자 - 
          agreed_date: nowDate, // 일자 - string
          hasReadAndAgreedTerms: true, // 잘 읽고 동의한건지에 대한 여부
          hasReadAndAgreedTermsDate: nowDateD, // 잘 읽고 동의한건지에 대한 여부
          version: '1.0' // [TODO] 약관 불러오기
        },
      }));
    else // 동의 취소의 경우
      setSignupData((prevData) => ({
        ...prevData,
        agreedToServicePolicy: {
          agreed: false, // 동의 여부 - 약식 동의
          date: null, // 일자 - 
          agreed_date: '', // 일자 - string
          hasReadAndAgreedTerms: false, // 잘 읽고 동의한건지에 대한 여부
          hasReadAndAgreedTermsDate: null, // 잘 읽고 동의한건지에 대한 여부
          version: '' // 약관
        },
      }));
  }



  return {
    step,
    submit,
    signupData,
    handleChange,
    incrementStep,
    decrementStep,

    agreePrivacyPolicy,
    agreeServicePolicy
  };
};
