import { GridBox } from '@/components';
import Banner from '@/components/Banner';
import GenreSelector from '@/components/GenreSelector'
import NovelItem from '@/components/NovelItem';
import { useNovelList } from '@/stores/novelList';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding: 134px 0;
`


export default function Page () {
  const { novelList, goToNextPage } = useNovelList();

  const [searchParams, setSearchParams] = useSearchParams();

  const genreParam = searchParams.get('genre');

  return (
    <Container>
      <GenreSelector/>

      <div className='mt-20'/>
      {genreParam === '추천' && 
        <>
          <Banner/>
          
          <div className='pl-16 font-20 black mt-40'>
            <span>신인작가들의 </span>
            <span className='c-primary'>도전, 어포튜니티!</span>
          </div>
        
        </>
      }
      

      {genreParam !== '추천' && 
        <>
          <div className='pl-16 font-20 black'>
            <span>신인작가님의 </span>
            <span className='c-primary'>{genreParam}</span>
          </div>
        </>
      }


      <div className='px-16 w-full pt-16'>
        <GridBox col={3} gap={20}>
          {novelList?.map((item, key) => 
            <NovelItem {...item} key={key}/>
          )}
        </GridBox>
      </div>
    </Container>
  )
}