import { sample_ad } from '@/assets'
import styled from 'styled-components'


const Container = styled.div`
  width: 100%;
  height: 25vw;
  background-color: #000;

  img {
    width: 100%;
    height: 100%;
  }
`

sample_ad
export default function AD () {

  return (
    <Container className='flex-center font-40 black c-white'>
      <img src={sample_ad}/>
    </Container>
  )
}