const theme = {
  primary: '#5c1b9c', // [INIT] Primary color 
  primary100: '#7e2cdd',
  primary200: '#FF7F2E',
  primary300: '#000000',

  black: '#000000',
  white: '#ffffff',
  text: '#000000',
  grey: '#E1E1E1',
  red: '#F03D3E',
  error: '#F03D3E'
};

const fonts = {
  default: `'Noto Sans KR'`,
  serif: `'Noto Serif KR'; serif`,
  number: `Roboto`,
};

export const getTheme = (key) => { 
  return key && theme[key] ? theme[key] : theme.gray900;
};

export const getFont = (key) => {
  return key && fonts[key] ? fonts[key] : fonts.default;
};

export default theme;
