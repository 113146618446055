import styled from 'styled-components'
import { Button, CheckBox, GridBox, LabelInput } from '..'
import { getTheme } from '@/styles/theme'
import { useSignup } from '@/stores/signup';
import { useState } from 'react';


const Container = styled.div`
  .gender-selector {
    width: 100%;
    padding: 12px 0;
    border: 2px solid #ececec;
    border-radius: 8px;
  }
`


export default function Step2 () {
  const { signupData, handleChange, incrementStep } = useSignup();

  const [hasSendMessage, setHasSendMessage] = useState(false);

  const [validateNumber, setValidateNumber] = useState('')

  // 인증하기
  function submit() {
    // [TODO] 인증번호 검증 로직
    if(validateNumber !== '') incrementStep();
    else alert('인증번호 입력 필요')
  }

  const sendMessage = () => {
    // [TODO] 인증번호 발송 통신
    if(signupData.phone !== '') setHasSendMessage(true);
    else alert('전화번호 입력 필요')
  }

  return (
    <Container>
      <div className='black font-30 c-primary100'>회원가입</div>
      <div className='bold font-24 mt-4 mb-72'>오드너리가 처음이신가요?</div>

      <LabelInput value={signupData.phone} onChange={e => handleChange('phone', e.target.value)} label={"전화번호"}/>
      <div className='mt-24'/>

      {hasSendMessage && <LabelInput value={validateNumber} onChange={e => setValidateNumber(e.target.value)} label={"인증번호"}/>}
      {/* [TODO] 남은 시간 표기 */}
      
      <div className='mt-48'/>

      {!hasSendMessage && <Button background={getTheme('primary100')} color={'#fff'} fontSize={20} style={{width: '100%'}} onClick={sendMessage}>인증번호 보내기</Button>}
      {hasSendMessage && <Button background={getTheme('primary100')} color={'#fff'} fontSize={20} style={{width: '100%'}} onClick={submit}>인증하기</Button>}

    </Container>
  )
}