import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`

  .book_cover {
    border-radius: 12px;
    aspect-ratio: 80 / 108;
    object-fit: cover;
  }
`


export default function NovelItem ({ book_cover, title, hasAudioBook, uuid }) {
  const navigate = useNavigate();

  return (
    <Container className='w-full pointer' onClick={() => navigate(`/novel/detail/${uuid}`)}>
      <div className='w-full'>
        <img src={book_cover} className='w-full book_cover'/>
      </div>

      <div className='font-14 mt-6 medium ellipsis-1'>{title}</div>
      <div className='font-12 mt-4 exlight'>
        <span>웹소설</span>
        {hasAudioBook && <span>, 오디오북</span>}
      </div>
    </Container>
  )
}