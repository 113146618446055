import { pick, isNil } from 'lodash';

export const formatNumber = (value) => {
  if (isNaN(value)) {
    return 0
  }
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatPhoneNumber = (str) => {
  if(str.length < 11) return ''
  return str.slice(0, 3) + '-' + str.slice(3, 7) + '-' + str.slice(7, 11)
}

// 날짜 포멧
export const formatDateYMD = (str) => {
  if(!str) return str
  if(str.length < 8) return str
  return str.slice(0,4) + '.' + str.slice(4, 6) + '.' + str.slice(6, 8)
}

// 날짜 포멧
export const formatDateYYMD = (str) => {
  if(!str) return str
  if(str.length < 8) return str
  return str.slice(0,4) + '-' + str.slice(4, 6) + '-' + str.slice(6, 8) + ' ' + str.slice(9, str.length)
}

// 날짜 포멧
export const foramtDateKR = (str) => {
  console.log(str)
  if(!str) return str
  if(str.length < 8) return str
  return str.slice(0,4) + '년' + str.slice(4, 6) + '월' + str.slice(6, 8) + '일 ' + str.slice(9, 11) + '시 ' + str.slice(12, 14) + '분'
}

export const formatTime = (milliseconds) => {
  // 밀리세컨드를 초로 변환
  let totalSeconds = Math.floor(milliseconds / 1000);
  let seconds = totalSeconds % 60;
  let minutes = Math.floor(totalSeconds / 60) % 60;
  let hours = Math.floor(totalSeconds / 3600);

  // 시간, 분, 초를 문자열로 변환하며, 조건에 따라 포맷팅
  let result = '';

  // 시간이 있을 경우에만 시간을 추가
  if (hours > 0) {
      result += `${hours}시간 `;
  }

  // 분이 있고, 총 시간이 1시간을 넘을 경우에만 분을 추가
  if (minutes > 0 && totalSeconds >= 3600) {
      result += `${minutes}분 `;
  } else if (minutes > 0) {
      result += `${minutes}분 `;  // 추가: 분이 있을 경우 항상 분을 표시
  }

  // 초가 있을 경우 초를 추가, 1초 미만인 경우 '0초'를 표시
  if (seconds > 0 || totalSeconds < 1) {
      result += `${seconds}초`;
  }

  return result.trim(); // 결과 문자열 반환, 불필요한 공백 제거
}


export const isProd = import.meta.env.VITE_APP_IS_PRODUCT === 'true';

