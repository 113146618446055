import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { fetchNovelDetailData, fetchNovelEpisodeList } from '@/api/novel';

const novelDataState = atom({
  key: 'novelData',
  default: {},
});


export const episodeListState = atom({
  key: 'episodeListState',
  default: [],
});


export const useNovelData = (uid) => {
    
  const [novelData, setNovelData] = useRecoilState(novelDataState);
  const [episodeList, setEpisodeList] = useRecoilState(episodeListState);
  
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getNovelData = async () => {
      try {
        const response = await fetchNovelDetailData(uid)
        setNovelData(response);
      } catch (error) {
        console.error('Failed to fetch novel genre list:', error);
      } finally {
        setIsLoading(false);
      }
    }

    const getEpisodeList = async () => {
      try {
        setIsLoading(true);
        const response = await fetchNovelEpisodeList(uid)
        setEpisodeList(response);
      } catch (error) {
        console.error('Failed to fetch novel genre list:', error);
      } finally {
        setIsLoading(false);
      }
    }

    if(uid) {
      getNovelData();
      getEpisodeList();
    }


  }, [])

  return {
    novelData,
    episodeList
  }
}