import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { fetchNovelEpisodeData } from '@/api/novel';

const episodeDataState = atom({
  key: 'episodeData',
  default: {},
});


export const useEpisodeData = (uid) => {
  const [episodeData, setEpisodeData] = useRecoilState(episodeDataState);
  
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getEpisodeData = async () => {
      try {
        const response = await fetchNovelEpisodeData(uid)
        setEpisodeData(response);
      } catch (error) {
        console.error('Failed to fetch novel genre list:', error);
      } finally {
        setIsLoading(false);
      }
    }


    if(uid) {
      getEpisodeData();
    }


  }, [])

  return {
    isLoading,
    episodeData
  }
}