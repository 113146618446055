import { getTheme } from '@/styles/theme'
import React, { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  position: relative;


  .inputLabel {
    color: #2D2D2D;
  }

  input {
    height: 52px;
    border: 0;
    width: 100%;
    background-color: ${({background}) => background};

    border: 1px solid #CCCCCC;
    border-radius: 8px;

    padding-left: 12px;

    color: ${getTheme('text')};
  }

  input:focus {
    border: 1px solid ${getTheme('primary')};
  }

  input::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
`

function LabelInput ({ 
  name, 
  required=false, 
  onChange, 
  label, 
  placeholder, 
  errorText='',
  validate=null, 
  doValidate=null, 
  background='#fff', 
  maxLength=30,
  ...rest 
}) {  
  return (
    <>
      <Container background={background}>
        <div className={`font-16 mb-6 medium inputLabel`}>{label}{required && <span className='c-red'>*</span>}</div>
        <input className={`font-16`} placeholder={placeholder} name={name} onChange={onChange} {...rest}/>
      </Container>
      {validate === false && <div className='absolute bold c-error font-12 mt-4'>{errorText}</div>}
    </>
  )
}

export default LabelInput