import React, { useRef } from 'react';
import styled from 'styled-components';
import { useClickOutside } from '@/hooks';
import { getTheme } from '@/styles/theme';

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  z-index: 9999999999;
  flex-direction: column;

  .modalInner {
    width: ${({width}) => `${width}px`};
    height: ${({height}) => `${height}px`};

    overflow-y: auto;


    position: relative;
    background-color: #fff;
    border-radius: 20px;

    padding: 0 42px;
    padding-top: 77px;
    padding-bottom: 47px;
  }

  .btn {
    width: 150px;
    height: 50px;
    border-radius: 60px;
  }

  .btn1 {
    background: ${getTheme('primary')};
  }
  .btn2 {
    background: #E8E8E8;
    color: #B0ABB5;
  }
`;

function SubmitModal({ children, onClose, isOpen, title='', submitText='', submit, width=400, height=250, ...rest }) {
  const ref = useRef();

  useClickOutside(ref, () => {
    onClose();
  });

  return (
    <>
      {isOpen && (
        <Container width={width} height={height}>
          <div className='modalInner' ref={ref} {...rest}>
            <>
              <div className='font-18 medium pre-line text-center'>{title}</div>
            
              <div className='flex-center mt-46'>
                <div className='btn1 btn mr-16 font-18 medium flex-center c-white pointer' onClick={submit}>{submitText}</div>
                <div className='btn2 btn font-18 medium flex-center pointer' onClick={onClose}>닫기</div>
              </div>
            </>
          </div>
        </Container>
      )}
    </>
  );
}

export default SubmitModal;
