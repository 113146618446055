import GenreSelector from '@/components/GenreSelector'
import styled from 'styled-components'


const Container = styled.div`
  width: 100vw;
  height: 99vh;
`


export default function Page () {

  return (
    <Container className='flex-center'>
      <GenreSelector/>

      <div className='medium font-28 text-center line-30'>오디오북은<br/>준비중입니다.</div>

    </Container>
  )
}