import { ic_odi } from '@/assets'
import Banner from '@/components/Banner'
import { useHideLayout } from '@/hooks'
import TitleHeader from '@/layouts/Header/TitleHeader'
import { formatNumber } from '@/utils/format'
import { Link } from 'react-router-dom'
import styled from 'styled-components'


const Container = styled.div`
  padding: 70px 0;

  .history_box {
    border-radius: 8px;
    border: 2px solid #e1e1e1;
  }

  .odi_medium {
    width: 30px;
  }
  .odi_small {
    width: 18px;
  }

  .grey_bottom_2 {
    border-bottom: 2px solid #e1e1e1;
  }

  .grey_bottom_1 {
    border-bottom: 1px solid #e1e1e1;
  }

  .price_box {
    border: 2px solid #000;
    width: 108px;
    height: 52px;
    border-radius: 8px;
  }
`


export default function Page () {


  useHideLayout('header', true)

  return (
    <Container>
      <TitleHeader title="오디나무"/>
      <Banner/>
      
      <div className='px-32 mt-28 mb-40'>
        <div className='w-full history_box'>
          <div className='flex-center px-10 py-10 grey_bottom_2'>
            <img className='odi_medium' src={ic_odi}/>
            <div className='ml-4 medium'>남은 오디</div>
            <div className='flex-1 ml-6 font-18 semibold'>172</div>

            <Link to='/charge/history'>구매내역</Link>
          </div>
          <div className='flex-center pl-16 pr-10 py-10'>
            <img className='odi_small' src={ic_odi}/>
            <div className='flex-1 ml-10 medium'>오디 자동충전</div>

            <Link to='/charge/auto'>신청하기</Link>
          </div>
        </div>
      </div>


      {
        SampleChargeList.map((item, idx) => (
          <OdiChargeItem
            quantity={item.quantity}
            price={item.price}
            key={`od-${idx}-${item.price}`}
          />
        ))
      }
    </Container>
  )
}



const OdiChargeItem = ({ quantity, price }) => {
  return (
    <div className='grey_bottom_1 flex-center py-14 px-16'>
      <img className='odi_medium' src={ic_odi}/>
      
      <div className='font-20 medium flex-1'>오디 {quantity}개</div>

      <div className='price_box pointer flex-center medium'>
        <span className='bold'>{formatNumber(price)}</span>원</div>
    </div>
  )
}




const SampleChargeList = [
  {
    quantity: 1,
    price: 100
  },
  {
    quantity: 5,
    price: 500
  },
  {
    quantity: 10,
    price: 1000
  },
  {
    quantity: 30,
    price: 3000
  },
  {
    quantity: 50,
    price: 5000
  },
  {
    quantity: 100,
    price: 10000
  },
  {
    quantity: 300,
    price: 30000
  },
]