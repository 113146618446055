import styled from 'styled-components'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getTheme } from '@/styles/theme';
import { useEffect, useState } from 'react';

const Container = styled.div`
  position: fixed;
  top: 96px;
  z-index: 20;
  width: 100%;
  border-bottom: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
  background-color: #fff;

  .inner_container {
    width: 100%;
    overflow-x: auto;
    display: flex;
    white-space: nowrap;
    max-width: 500px;
  }

  .genre-item {
    font-size: 12px;
    padding: 10px 0px;
    margin: 0px 10px;
    cursor: pointer;
    color: black;
    border-bottom: 2px solid transparent;
    transition: color 0.3s, border-bottom 0.3s;
    background-color: #fff;
    flex-shrink: 0; /* Prevent items from shrinking */
  }

  .genre-item.active {
    font-weight: 800;
    color: ${getTheme('primary')};
    border-bottom: 2px solid ${getTheme('primary')};
  }

  .genre-item:not(.active):hover {
    color: gray;
  }
`


const GenreList = ['추천', '신작', '현대 판타지', '무협', '로맨스', 'BL', '로맨스 판타지', '판타지']
const RankingGenreList = ['전체', '신작', '현대 판타지', '무협', '로맨스', 'BL', '로맨스 판타지', '판타지']

// isRankingPage

// 
export default function GenreSelector ({ isRankingPage=false }) {
  const [activeGenre, setActiveGenre] = useState(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const genre = searchParams.get('genre');
    if (genre) {
      setActiveGenre(genre);
    } else {
      const InitValue = (isRankingPage ? RankingGenreList : GenreList)[0];
      handleGenreClick(InitValue)
    }
  }, [searchParams]);

  const handleGenreClick = (genre) => {
    setActiveGenre(genre);
    setSearchParams({ genre });
    navigate(`?genre=${genre}`);
  };
  return (
    <Container className='flex-center'>
      <div className='inner_container'>
        {(isRankingPage ? RankingGenreList : GenreList).map((genre) => (
          <div
            key={genre}
            className={`bold font-14 genre-item ${activeGenre === genre ? 'active' : ''}`}
            onClick={() => handleGenreClick(genre)}
          >
            {genre}
          </div>
        ))}
      </div>
    </Container>
  )
}