import { getTheme } from '@/styles/theme'
import React, { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  position: relative;

  textarea {
    height: ${({isMobile}) => isMobile ? '100px' : '200px'};
    width: 100%;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    background-color: #fff;
    padding: 12px;
    color: ${getTheme('text')};
    resize: none;
  }

  
  textarea::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }

  textarea:focus {
    border: 1px solid ${getTheme('primary')};
  }

`

function Textarea ({ name, onChange, placeholder, isMobile=false, ...rest }) {
  
  function handleChange(e) {
    onChange(e)
  }

  return (
    <Container isMobile={isMobile}>
      <textarea name={name} onChange={handleChange} className={isMobile ? 'font-14' : 'font-16'} placeholder={placeholder} {...rest}/>
    </Container>
  )
}

export default Textarea