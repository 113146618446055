import React, { useState } from 'react';
import styled from 'styled-components';
import { useUser } from '@/stores';
import { Button, LabelInput } from '@/components';
import { main_logo } from '@/assets';
import { useHideLayout } from '@/hooks';
import { getTheme } from '@/styles/theme';
import { Link } from 'react-router-dom';

const Container = styled.div`
  width: 100vw;
  height: 100vh;

  .inputBox {
    input {
      background-color: #fff !important;
    }
  }

  
  .logoBar {
    width: 100%;
    height: 94px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
  }

  .loginBox {
    border-radius: 20px;
    background-color: #fff;
    padding: 0 32px;
    padding-top: 57px;
  }

  .c-c3c3c3 {
    color: #c3c3c3;
  }
`;

function LoginPage() {
  const { userLocalSignin } = useUser()

  const [state, setState] = useState(true)
  
  const [id, setId] = useState('')
  const [password, setPassword] = useState('')

  // TODO 회원 생성에 이은 로그인 로직 추가 필요
  async function getLogin() {
    const result = await userLocalSignin({ id, password })

    if(result) {
      window.location.href ='/'
    }
    else {
      setState(false)
    }
  }

  useHideLayout('header', true);


  return (
    <Container className='flex-col flex-center'>
      <div className='inputBox w-full'>
        <div className='flex-center logoBar'>
          <img style={{width: '152px'}} className='' src={main_logo}/>
        </div>
        
        <div className='loginBox'>
          <div className='mb-80'>
            <div className='font-30 c-primary100 black'>LOGIN</div>
            <div className='font-24 exbold'>오드너리에 어서오세요!</div>
          </div>


          <LabelInput label={"아이디"} placeholder="아이디를 입력해주세요." value={id} onChange={e => setId(e.target.value)}/>

          <div className='mt-18'/>
          <LabelInput label={"비밀번호"} placeholder="비밀번호를 입력해주세요." value={password} onChange={e => setPassword(e.target.value)} type="password"/>
          {!state && <div className='font-16 bold text-center mt-16' style={{width: '100%', color: '#ff4545'}}>아이디와 비밀번호를 다시 확인해주세요.</div>}
          
          <div className='mt-40'></div>
          <Button background={getTheme('primary100')} color={'#fff'} fontSize={20} style={{width: '100%'}} onClick={getLogin}>로그인</Button>
          

          <div className='text-center mt-24'>
            <div className='font-14 mb-4 medium c-c3c3c3'>오드너리가 처음이시라면?</div>
            <Link to='/signup' className='bold c-primary100 mt-4'>회원가입</Link>
          </div>
          
        </div>
      </div>
    </Container>
  )
}


export default LoginPage