export default function EpisodeSectionItem ({ item, isLastItem }) {
  return (
    <div className='pre-line font-16 w-full line-30'>
      {/* 구간 출력 */}
      {item}
      
      {/* 마지막 구간이면 공백을 출력하지 않음 */}
      {!isLastItem && 
        <>
          <br/>
          <br/>
        </>
      }
    </div>
  )
}


