import { useEffect } from 'react';

const useHideLayout = (type, match) => {
  let id = type

  if(id === 'header') id = 'top-header'
  
  useEffect(() => {
    if (type === 'header') {
      const el = document.getElementById(id);
      if (!el) return;
      match ? (el.style.visibility = 'hidden') : (el.style.visibility = 'visible');

      return () => {
        el.style.visibility = 'visible';
      };
    }
    
    if (type === 'footer') {
      const el = document.getElementById(id);
      if (!el) return;
      match ? (el.style.display = 'none') : (el.style.display = 'block');

      return () => {
        el.style.visibility = 'visible';
      };
    }

    if (type === 'logoHeader') {
      const el = document.getElementById('logoHeader');
      if (!el) return;
      match ? (el.style.display = 'none') : (el.style.display = 'block');

      return () => {
        el.style.visibility = 'visible';
      };
    }
    
    if (type === 'bottomNavigation') {
      const el = document.getElementById('bottomNavigation');
      if (!el) return;
      match ? (el.style.display = 'none') : (el.style.display = 'flex');

      return () => {
        el.style.display = 'flex';
      };
    }
  }, [id, match, type]);
};

export default useHideLayout;
